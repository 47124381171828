<template>
	<div class="multipleBox">
		<div class="title">
				<span class="cancel-btn" @click="cancel">取消</span>
				<span> 请选择{{ title }}</span>
				<span @click="handleSubmit" class="btn">确定</span>
		</div>
		<div class="box_group">
			<van-checkbox-group v-model="result" ref="checkbox">
				<van-cell-group>
					<van-cell v-for="(item, index) in options" clickable :key="item.name" :title="item.label" @click="toggle(index)">
						<template #right-icon>
							<van-checkbox :name="item.name" ref="checkboxes">
								<template #icon="props">
									<img v-if="result.includes(item.name)" src="@/assets/image/success.svg" class="checked-icon" />
								</template>
							</van-checkbox>
						</template>
					</van-cell>
				</van-cell-group>
			</van-checkbox-group>
		</div>
	</div>
</template>

<script>
import { Checkbox, CheckboxGroup, CellGroup, Cell } from 'vant';
export default {
	components: {
		[Checkbox.name]: Checkbox,
		[CheckboxGroup.name]: CheckboxGroup,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
	},
	data() {
		return {
			result: [],
		};
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		options: {
			type: Array,
			default: () => [],
		},
		selectData: {
			type: Array,
			default: () => [],
		},
	},
	watch: {
		selectData: {
			handler(val) {
				if (val.length) {
					this.result = val;
				}
			},
			deep: true,
			immediate: true,
		},
	},

	methods: {
		handleSubmit() {
			const data = this.options.filter(item => {
				return this.result.includes(item.name);
			});
			const result = data.map(v => v.name);
			const label = data.map(v => v.label).join(',');

			this.$emit('submitCountry', { result, label, data });
		},
		cancel() {
			this.$emit('cancel');
			this.result = this.selectData;
		},
		toggle(index) {
			this.$refs.checkboxes[index].toggle();
		},
	},
};
</script>

<style lang="scss" scoped>
.multipleBox {
	max-height: 7rem;
	display: flex;
	flex-direction: column;
}
.box_group{
	flex: 1;
	overflow-y: scroll;
}
.van-checkbox {
	display: flex;
	min-height: 0.3rem;
}
.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.2rem;
}
.btn {
	color: $b_color_main;
}
.cancel-btn {
	color: $font_color_info;
}
.checked-icon {
	width: 0.4rem;
	height: 0.4rem;
}
::v-deep .van-checkbox__icon .van-icon {
	border: none;
}
</style>
